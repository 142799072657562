<template>
  <div role="main" :id="getRdStationId()"></div>
</template>

<script>
export default {
  methods: {
    getRdStationId() {
      if (this.$route.params.lang === "es") {
        return "website-formulario-espanol-df611be999a300f77476";
      } else {
        return "website-4ae0021dd7491b575692";
      }
    },
    initRdStationForm() {
      const script = document.createElement('script');
      script.src = 'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js';
      script.onload = () => {
        if (window.RDStationForms) {
          new window.RDStationForms(this.getRdStationId(), 'null').createForm();
        } else {
          console.error('RDStationForms not loaded properly');
        }
      };
      document.head.appendChild(script);
    }
  },
  mounted() {
    this.initRdStationForm();
  }
};
</script>
