<template>
  <div>
    <PageHeader fileName="header-3" withTablet withMobile>
      <h3>{{ $lang("page_header") }}</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <h2 class="mb-4 h2-title" v-html="$lang('section1.heading')" />
            <h4>{{ $lang("section1.subheading") }}</h4>
          </div>
          <div class="col-lg-6 offset-lg-1 mt-4 mt-lg-0">
            <h6 class="fw-400 mb-4 lh-3">
              {{ $lang("section1.text1") }}
            </h6>
            <p>
              {{ $lang("section1.text2") }}
            </p>
            <p>
              {{ $lang("section1.text3") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6 bg-dark">
      <div class="container">
        <div class="row mb-6">
          <div class="col-lg-5">
            <h3 v-html="$lang('section2.title')" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>{{ $lang("section2.description") }}</p>
            <p>{{ $lang("section2.description2") }}</p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-5">
            <h4 class="mb-5" v-html="$lang('section2.heading')" />
            <ImageContainer fileName="levels" :size="400" contain />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <ConnectedList
              collapse
              :items="$lang('section2.items')"
              :defaultActiveIndex="0"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("section3.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-4 mb-3 mb-lg-0">
            <CardButton
              :title="$lang('section3.card2.heading')"
              :to="{ path: '/courses/our-courses' }"
              theme="primary"
            >
              <small class="m-0"> {{ $lang("section3.card2.text") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-4 mb-3 mb-lg-0">
            <CardButton
              :title="$lang('section3.card3.heading')"
              :to="{ name: 'extra_activities' }"
              theme="primary"
            >
              <small class="m-0">
                {{ $lang("section3.card3.text") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-3" theme="primary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import ImageContainer from "@/components/ImageContainer.vue";
import CardButton from "@/components/CardButton.vue";
import ConnectedList from "@/components/ConnectedList.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    ImageContainer,
    CardButton,
    ConnectedList,
  },
};
</script>

<style></style>
